@charset "UTF-8";
/* ▼モバイルファースト (全環境に共通のデザイン) */
.l-main .c-titleH2 {
  text-align: center;
}

.l-main .mv-wrap {
  margin-bottom: 32px;
  padding-top: 8px;
  background: #fff;
}

.l-main .search-wrap {
  padding: 32px 0 56px;
}

.l-main .search-wrap .c-roundbox {
  padding: 16px 24px 32px;
}

.l-main .search-wrap .c-roundbox + .c-roundbox {
  margin-top: 32px;
}

.l-main .search-wrap .ttl-searchBlock {
  margin-bottom: 20px;
  font-size: 2.1rem;
  line-height: 1;
  text-align: center;
}

.l-main .search-wrap .ttl-searchBlock .icon {
  display: block;
}

.l-main .search-wrap .c-searchLabel .text {
  font-size: 1.6rem;
}

.l-main .search-wrap .btn-wrap {
  margin-top: 28px;
}

.l-main .search-wrap .btn-wrap .c-list {
  margin-bottom: 24px;
}

.l-main .search-wrap .is-clf {
  padding-top: 16px;
}

.l-main .search-wrap .txt-date {
  font-family: "Ubuntu", "游ゴシック体", YuGothic, "Yu Gothic Medium", "Yu Gothic UI", sans-serif;
  font-size: 1.4rem;
  line-height: 1;
}

.l-main .search-wrap .li-servicearea dt {
  font-weight: bold;
}

.l-main .search-wrap .li-servicearea + .li-servicearea {
  margin-top: 24px;
  display: flex;
}

.l-main .search-wrap .li-servicearea + .li-servicearea dt {
  padding-right: 24px;
}

.l-main .li-about.c-cardWrap.col1to1 .c-card {
  padding-bottom: 8px;
}

.l-main .li-about.c-cardWrap.col1to1 .c-card:before, .l-main .li-about.c-cardWrap.col1to1 .c-card:after {
  bottom: 18px;
}

.l-main .li-about.c-cardWrap.col1to1 .c-card .c-title {
  padding-right: 40px;
  font-size: 2.1rem;
  font-style: italic;
  text-align: center;
}

.l-main .li-about.c-cardWrap.col1to1 .c-card .c-title .icon {
  display: inline-block;
  max-width: 36px;
  max-height: 36px;
}

.l-main .li-about.c-cardWrap.col1to1 .c-card .text {
  padding: 12px 8px 4px;
}

.l-main .box-rss {
  font-size: 1.4rem;
}

.l-main .ui-moreWrap .btn-wrap {
  margin-top: 24px;
}

.l-main .li-app {
  margin-top: 48px;
}

.l-main .li-app li {
  height: 80px;
}

.l-main .li-app li a {
  display: flex;
  align-items: center;
  height: 100%;
  font-weight: bold;
  text-align: left;
}

.l-main .li-app .icon {
  width: 24px;
  text-align: center;
}

.l-main .li-app .text {
  padding-left: 12px;
}

.l-main .c-snsList {
  margin-top: 20px;
}

/* ▼モバイルのみ付け足すデザイン */
@media all and (max-width: 767px) {
  .l-main .ui-sliderWrap {
    padding-bottom: 4px;
  }
  .l-main .c-cardWrap.col1to3.li-service .c-title {
    font-size: 1.8rem;
  }
}

/* ▼タブレット用デザインとして付け足すデザイン */
@media all and (min-width: 768px) {
  .l-main {
    /*mv-wrap */
  }
  .l-main .mv-wrap .ui-slider a {
    display: block;
    overflow: hidden;
    border-radius: 0 0 56px 0;
  }
  .l-main .mv-wrap .ui-slider a img {
    will-change: transform;
    transform: rotate(0);
    transform-origin: center center;
    transition: ease-in-out .4s;
  }
  .l-main .mv-wrap .ui-slider a:hover img {
    transform: scale(1.03) rotate(0.01deg);
  }
  .l-main .search-wrap {
    padding: 16px 0 56px;
  }
  .l-main .search-wrap .c-roundbox {
    padding: 32px 24px;
  }
  .l-main .search-wrap .ttl-searchBlock {
    font-size: 2.0rem;
  }
  .l-main .li-about.c-cardWrap.col1to1 .c-card {
    padding-bottom: 16px;
  }
  .l-main .li-about.c-cardWrap.col1to1 .c-card:before, .l-main .li-about.c-cardWrap.col1to1 .c-card:after {
    bottom: 29px;
  }
  .l-main .li-about.c-cardWrap.col1to1 .c-card .c-title {
    font-size: 2.8rem;
  }
  .l-main .li-about.c-cardWrap.col1to1 .c-card .c-title .icon {
    transform: translateY(-2px);
    max-width: none;
    max-height: none;
    margin-right: 8px;
  }
  .l-main .li-about.c-cardWrap.col1to1 .c-card .c-title .icon img {
    vertical-align: middle;
  }
  .l-main .li-about.c-cardWrap.col1to1 .c-card .text {
    padding: 16px 20px 0;
  }
  .l-main .li-app li a {
    height: 80px;
    font-weight: normal;
  }
  .l-fixedSearchNav {
    display: none;
  }
}

@media all and (min-width: 900px) {
  .l-main .search-wrap {
    display: flex;
  }
  .l-main .search-wrap .c-roundbox {
    flex: 1;
  }
  .l-main .search-wrap .c-roundbox + .c-roundbox {
    margin-top: 0;
  }
  .l-main .search-wrap .c-roundbox.box-delay {
    width: 37.8906%;
    margin: 0 8px;
  }
  .l-main .search-wrap .ttl-searchBlock {
    margin-bottom: 32px;
  }
  .l-main .search-wrap .li-servicearea + .li-servicearea {
    display: block;
  }
  .l-main .search-wrap .c-list.arrow {
    font-size: 1.4rem;
  }
  .l-main .search-wrap .c-list.arrow li:before {
    top: 0.35em;
  }
  .l-main .search-wrap .is-clf {
    padding-top: 12px;
  }
  .l-main .li-app {
    margin-top: 36px;
  }
  .l-main .li-app li {
    height: auto;
  }
  .is-ie11 .l-main .search-wrap .c-list.arrow li:before {
    transform: translateY(0px);
  }
  .l-fixedSearchNav {
    display: none;
  }
}

/* ▼PC用デザインとして付け足すデザイン */
@media all and (min-width: 1024px) {
  .l-main .mv-wrap {
    margin-top: 15px;
    padding-top: 18px;
  }
  .l-main .mv-wrap .slick-slide {
    padding: 0 8px;
  }
  .l-main .search-wrap .box-delay {
    flex: none;
    width: 37.890625%;
  }
}
